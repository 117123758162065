import { Component } from '@angular/core';
import {FacebookService} from './share/facebook.service'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kaewkwan2019';

  public post$: Observable<any[]>;

  constructor(
    private _fb: FacebookService
  ) {
    this.post$ = this._fb.posts;
  }
}
