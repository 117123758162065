import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  toggleMenu(event) {
    event.target.classList.toggle('back');
    const menu: any = document.getElementsByClassName('nav-menu')[0];
    menu.classList.toggle('show');
  }

  closeMenu() {
    const closeBtn: any = document.getElementsByClassName('menu-btn')[0];
    const menu: any = document.getElementsByClassName('nav-menu')[0];
    menu.classList.remove('show');
    closeBtn.classList.remove('back')
  }
}
