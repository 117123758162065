import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { FacebookService as FBService, InitParams } from 'ngx-facebook'
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import * as _ from 'lodash'

export const config = environment.facebook

declare const window: any

declare const FB: any

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  private _posts: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null)

  get posts() {
    return this._posts.asObservable();
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private fb: FBService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const fbInitParams: InitParams = {
        appId            : config.appId,
        xfbml            : false,
        version          : config.version
      }

      fb.init(fbInitParams).then(() => {
        this.getPosts()
      })
    }
  }

  private getPosts(): void {
    try {
      this.fb.api(`${config.endpoint}/feed`, 'get', {
      fields: 'message,picture,full_picture,link',
        limit: 8,
        access_token: config.appToken
      }).then((data) => {
        console.log(data)
        if (!data.error) {
          this._posts.next(_.map(data.data, _.clone));
        } else {
          throw data.error
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
}


