import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { HeaderComponent } from './layouts/header/header.component';
import { NavigationComponent } from './layouts/navigation/navigation.component';
import { FooterComponent } from './layouts/footer/footer.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: './modules/pages/pages.module#PagesModule'
  }
];

@NgModule({
  declarations: [
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    FontAwesomeModule,
  ],
  exports: [
    RouterModule,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
  ]
})
export class AppRoutingModule { }
